<template>
    <div class="container" id="EnrollDetail">
        <el-page-header @back="$router.back()" :content="['创建赛事报名设置','编辑赛事报名设置','查看报名设置信息'][type]"></el-page-header>
        <div class="EnrollDetail_content">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
                <el-form-item label="赛事:" prop="subLeagueId" v-if="type == 0">
                    <el-select v-model="subLeagueId" placeholder="请选择">
                        <el-option
                            v-for="(item,index) in leagueList" :key="index"
                            :label="item.SubLeagueName"
                            :value="item.SubLeagueId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="赛事名称:" v-else>{{leagueObj.SubLeagueName}}</el-form-item>
                <template v-if="subLeagueId && subLeagueId > 0">
                    <el-form-item label="比赛时间:">
                        <template v-if="leagueObj.GameBeginTime && leagueObj.GameEndTime">
                            {{leagueObj.GameBeginTime | dateFormat('yyyy-MM-dd HH:mm:ss')}} ~ {{leagueObj.GameEndTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}
                        </template>
                        <template v-else>未定</template>
                    </el-form-item>
                    <el-form-item label="报名时间:" prop="time" v-if="leagueObj.GameBeginTime && leagueObj.GameEndTime">
                        <el-date-picker
                            v-model="form.time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="GameTimeOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="报名时间:" prop="time" v-else>
                         <el-date-picker
                            v-model="form.time[0]"
                            type="datetime"
                            placeholder="开始日期"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="startGameTimeOptions">
                         </el-date-picker>
                         <span>&ensp;至&ensp;</span>
                         <el-date-picker
                            v-model="form.time[1]"
                            type="datetime"
                            placeholder="结束时间"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="endGameTimeOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="报名须知:">
                        <Editor v-model="form.detail" :oss-options="this.UploaderOptions"></Editor>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-descriptions title="队员信息设置"></el-descriptions>
                    <div class="faild">
                        <el-form-item label="球衣号码:">
                            <el-radio-group v-model="field.jerseyNumber">
                                <el-radio :label="1">使用（仅对球员）</el-radio>
                                <el-radio :label="0">不使用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="肖像照:">
                            <el-radio-group v-model="field.portraitImg">
                                <el-radio :label="0">不要求必须上传</el-radio>
                                <el-radio :label="1">运动员必须上传，其他身份不要求必须上传</el-radio>
                                <el-radio :label="9">所有队员必须上传</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- <ul>
                            <li v-for="(item,index) in fieldList" :key="index"></li>
                        </ul> -->
                        <!-- <el-button type="primary" size="small" @click="addRole()" title="点击添加一行填写"><i class="el-icon-plus"></i> 增加角色</el-button> -->
                    </div>
                    <el-divider></el-divider>
                    <el-descriptions title="队员身份设置"></el-descriptions>
                    <div class="role">
                        <ul>
                            <li v-for="(item,index) in roleList" :key="index">
                                <div>
                                    <el-checkbox v-model="item.isExist" :disabled="item.isAthlete == 1">
                                        <span v-if="item.name">{{item.name}}</span>
                                        <el-input v-model.trim="item.nameValue" maxlength="10" v-else></el-input>
                                    </el-checkbox>
                                </div>
                                <div>
                                    <span>人数限制：最少：</span>
                                    <el-input v-model.number="item.min"></el-input>人
                                    <span>~</span>
                                    <span>最多：</span><el-input v-model.number="item.max"></el-input>人
                                </div>
                                <div v-if="item.isAthlete != 1">
                                    <el-checkbox v-model="item.isMerger">可兼任运动员</el-checkbox>
                                </div>
                                <div v-if="!item.name">
                                    <el-button type="danger" @click="removeRole(index)" size="mini" style="margin-left:20px">删除</el-button>
                                </div>
                            </li>
                        </ul>
                        <el-button type="primary" size="small" @click="addRole()" title="点击添加一行身份填写"><i class="el-icon-plus"></i> 增加身份</el-button>
                    </div>
                    <template v-for="(item,index) in groups">
                        <el-divider :key="'a'+index"></el-divider>
                        <el-descriptions :title="item.LeagueGroupName + '设置'" :key="'b'+index"></el-descriptions>
                        <div class="group_limit" :key="'c'+index">
                            <el-form-item label="运动员性别限制:" label-width="130px" required style="margin-bottom:20px">
                                <el-radio-group v-model="item.GenderLimit" border>
                                    <template v-if="item.GroupType != 3">
                                        <el-radio :label="1">男</el-radio>
                                        <el-radio :label="2">女</el-radio>
                                    </template>
                                    <template v-else>
                                        <el-radio :label="1">男双（二男）</el-radio>
                                        <el-radio :label="2">女双（二女）</el-radio>
                                        <el-radio :label="4">混双（一男一女）</el-radio>
                                    </template>
                                    <el-radio :label="3">男女不限</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <template v-if="[1,3,4].includes(item.GenderLimit)">
                                <el-form-item :label="([3,4].includes(item.GenderLimit)?'男':'') + '运动员年龄限制:'" 
                                label-width="130px" 
                                :prop="'maleBirthday'+index" 
                                :rules="rules.birthday"  
                                style="margin-bottom:20px">
                                    <div class="more">
                                        <div>
                                            <span class="label">最早出生：</span>
                                            <el-input v-model.number="item.MaleBirthdayMin[0]"></el-input>年
                                            <el-input v-model.number="item.MaleBirthdayMin[1]"></el-input>月
                                            <el-input v-model.number="item.MaleBirthdayMin[2]"></el-input>日
                                        </div>
                                        <div>
                                            <span class="label">最晚出生：</span>
                                            <el-input v-model.number="item.MaleBirthdayMax[0]"></el-input>年
                                            <el-input v-model.number="item.MaleBirthdayMax[1]"></el-input>月
                                            <el-input v-model.number="item.MaleBirthdayMax[2]"></el-input>日
                                        </div>
                                    </div>
                                </el-form-item>
                            </template>
                            <template v-if="[2,3,4].includes(item.GenderLimit)">
                                <el-form-item :label="([3,4].includes(item.GenderLimit)?'女':'') + '运动员年龄限制:'" 
                                label-width="130px" 
                                :prop="'femaleBirthday'+index" 
                                :rules="rules.birthday" 
                                style="margin-bottom:20px">
                                    <div class="more">
                                        <div>
                                            <span class="label">最早出生：</span>
                                            <el-input v-model.number="item.FemaleBirthdayMin[0]"></el-input>年
                                            <el-input v-model.number="item.FemaleBirthdayMin[1]"></el-input>月
                                            <el-input v-model.number="item.FemaleBirthdayMin[2]"></el-input>日
                                        </div>
                                        <div>
                                            <span class="label">最晚出生：</span>
                                            <el-input v-model.number="item.FemaleBirthdayMax[0]"></el-input>年
                                            <el-input v-model.number="item.FemaleBirthdayMax[1]"></el-input>月
                                            <el-input v-model.number="item.FemaleBirthdayMax[2]"></el-input>日
                                        </div>
                                    </div>
                                </el-form-item>
                            </template>
                            <el-form-item label="运动员人数限制:" label-width="130px" v-if="item.GroupType != 3" :key="1">
                                <div class="more number">
                                    <div>
                                        <span class="label">最少：</span>
                                        <el-input v-model.number="item.AthletesMin" :disabled="item.GroupType == 1"></el-input>人
                                    </div>
                                    <span>~</span>
                                    <div>
                                        <span class="label">最多：</span>
                                        <el-input v-model.number="item.AthletesMax"></el-input>人
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="运动员组合对数限制:" label-width="130px" v-else :key="2">
                                <div class="more number">
                                    <div>
                                        <span class="label">最少：</span>
                                        <el-input v-model.number="item.AthletesMin" disabled></el-input>对
                                    </div>
                                    <span>~</span>
                                    <div>
                                        <span class="label">最多：</span>
                                        <el-input v-model.number="item.AthletesMax"></el-input>对
                                    </div>
                                </div>
                            </el-form-item>
                            <template v-if="item.GroupType != 3 && [3].includes(item.GenderLimit)">
                                <el-form-item label="其中，男运动员人数:" label-width="160px">
                                    <div class="more number">
                                        <div>
                                            <span class="label">最少：</span>
                                            <el-input v-model.number="item.MaleAthletesMin"></el-input>人
                                        </div>
                                        <span>~</span>
                                        <div>
                                            <span class="label">最多：</span>
                                            <el-input v-model.number="item.MaleAthletesMax"></el-input>人
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="女运动员人数:" label-width="160px">
                                    <div class="more number">
                                        <div>
                                            <span class="label">最少：</span>
                                            <el-input v-model.number="item.FemaleAthletesMin"></el-input>人
                                        </div>
                                        <span>~</span>
                                        <div>
                                            <span class="label">最多：</span>
                                            <el-input v-model.number="item.FemaleAthletesMax"></el-input>人
                                        </div>
                                    </div>
                                </el-form-item>
                            </template>
                            <el-form-item label="报名方式:" :prop="`enrollWay${index}`" :rules="rules.enrollWay" style="margin-bottom:20px">
                                <el-checkbox-group v-model="item.EnrollWay" :disabled="item.GroupType == 2">
                                    <el-checkbox :label="1">代表队报名</el-checkbox>
                                    <el-checkbox :label="2">个人报名</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="组别说明:">
                                <el-input type="textarea" resize="none" :rows="3" v-model="item.LeagueGroupDesc" style="width: 100%;max-width:400px;"></el-input>
                            </el-form-item>
                        </div>
                    </template>
                    <el-divider></el-divider>
                    <el-descriptions title="法律文件设置"></el-descriptions>
                    <div class="files">
                        <ul>
                            <li v-for="(item,index) in files" :key="index">
                                <span>{{index + 1}}.</span>
                                <div class="button">
                                    <el-button type="primary" size="small">
                                        <span>上传文件（pdf）</span>
                                        <input type="file" @change="fileChange($event,index)">
                                    </el-button>
                                </div>
                                <div class="state">
                                    <span class="state1" v-if="item.url">已上传{{item.state == 2 ? item.size : ''}}</span>
                                    <span class="state2" v-else-if="item.state == 1"><i class="el-icon-loading"></i>正在上传</span>
                                    <span class="state3" v-else-if="item.state == -1"><i class="el-icon-error"></i>上传失败</span>
                                </div>
                                <el-form-item label="文件名称:">
                                    <el-input v-model.trim="item.name" placeholder="文件名称最长不超过20个字符" maxlength="20"></el-input>
                                </el-form-item>
                                <div v-show="files.length > 1">
                                    <el-button type="danger" size="small" @click="removeFile(index)">删除</el-button>
                                </div>
                            </li>
                        </ul>
                        <el-button type="primary" size="small" @click="addFile()" title="点击增加一行文件上传" style="margin-left:30px"><i class="el-icon-plus"></i> 增加文件上传</el-button>
                    </div>
                </template>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px">
                <el-button type="success" @click="save()" style="width:100px">保存</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import {
    GetCreateLeagueEnrollInitInfo,
    GetLeagueEnrollDetail,
    GetTeamUserRoleList,
    CreateLeagueEnroll,
    EditLeagueEnroll,
    GetLastLeagueEnrollDetail,
    GetLeagueEventsGroup
} from '@/api'
import {
    dateFormat,
    formatFileSize
} from '@/assets/js';
import OSS from 'ali-oss'
import { nanoid } from "nanoid"
import Editor from '@/components/Editor';
export default {
    components: { 
        Editor
    },
    data(){
        var leagueEnrollId = parseInt(this.$route.params.leagueEnrollId),
            subLeagueId = parseInt(this.$route.params.subLeagueId),
            type = parseInt(this.$route.params.type);
        return {
            type: isNaN(type) ? 0 : type,//0 创建 1 修改
            Id: isNaN(leagueEnrollId) ? 0 : leagueEnrollId,
            subLeagueId: isNaN(subLeagueId) ? null : subLeagueId,
            leagueList:[],
            leagueObj:{},
            form:{
                time:[],
                detail:"",
            },
            field:{
                jerseyNumber: 0,
                portraitImg: 0
            },
            roleList:[],
            groups:[],
            files:[],
            rules:{
                subLeagueId:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(this.subLeagueId == null){
                            callback(new Error('请选择赛事'));
                        }else{
                            callback();
                        }
                    }}
                ],
                time:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0){
                            callback(new Error('请选择报名时间'));
                        }else if(!(this.leagueObj.GameBeginTime && this.leagueObj.GameEndTime) && !value[0]){
                            callback(new Error('请选择报名开始时间'));
                        }else{
                            callback();
                        }
                    }}
                ],
                birthday:[
                    {validator:(rule, value, callback)=>{
                        var index = parseInt(rule.field.substr(-1));
                        var obj = this.groups[index];
                        var y = new Date().getFullYear(),
                            min = [],
                            max = [],
                            error = null;
                        if(rule.field.indexOf('femaleBirthday') > -1){
                            min = obj.FemaleBirthdayMin,
                            max = obj.FemaleBirthdayMax;
                        }else{
                            min = obj.MaleBirthdayMin,
                            max = obj.MaleBirthdayMax;
                        }
                        let minY = min[0], minM = min[1], minD = min[2],
                            maxY = max[0], maxM = max[1], maxD = max[2];
                        if((minY && minY > y) || (maxY && maxY > y)){
                            error = '出生年份不能大于今年';
                        }else if((minY && minY < 1900) || (maxY && maxY < 1900)){
                            error = '出生年份不能小于1900年';
                        }else if(minY && maxY && minY > maxY){
                            error = '最早出生年份不能大于最晚出生年份';
                        }else if(minY && new Date(`${minY}-${minM}-${minD}`) == 'Invalid Date'){
                            error = '最早出生日期格式不正确';
                        }else if(maxY && new Date(`${maxY}-${maxM}-${maxD}`) == 'Invalid Date'){
                            error = '最晚出生日期格式不正确';
                        }
                        if(error){
                            callback(new Error(error));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'}
                ],
                enrollWay:[
                    {required:true,validator:(rule, value, callback)=>{
                        var index = parseInt(rule.field.substr(-1));
                        value = this.groups[index].EnrollWay;
                        if(!value || value.length == 0){
                            callback(new Error('请选择报名方式'));
                        }else{
                            callback();
                        }
                    }}
                ]
            },
            fieldList:[{
                isExist:true,
                name:"姓名",
                existDisabled:true,
                isRequired:1
            },{
                isExist:true,
                name:"手机号",
                existDisabled:true,
                isRequired:1
            },{
                isExist:true,
                name:"证件类型",
                existDisabled:true,
                children:{
                    list:[{
                            text:'身份证',
                            disabled:true
                        },{
                            text:'护照'
                        }],
                    value:['身份证','护照']
                },
                isRequired:1
            },{
                isExist:true,
                name:"证件号码",
                existDisabled:true,
                isRequired:1
            },{
                isExist:true,
                name:"出生日期",
                existDisabled:true,
                isRequired:1
            },{
                isExist:true,
                name:"性别",
                existDisabled:true,
                isRequired:1
            },{
                isExist:false,
                name:"证件照片",
                existDisabled:false,
                isRequired:0
            },{
                isExist:false,
                name:"肖像照片",
                existDisabled:false,
                isRequired:0
            }],
            UploaderOptions:{}
        }
    },
    created(){
        GetCreateLeagueEnrollInitInfo({
            LeagueEnrollId: this.Id
        }).then(data=>{
            if(data.Code == 0){
                this.UploaderOptions = data.UploadOption;
                if(this.type == 0){
                    this.Id = data.LeagueEnrollId;
                    this.leagueList = data.LeagueList;
                }
            }
        })
        if(this.type == 1){
            GetLeagueEnrollDetail({
                LeagueEnrollId: this.Id,
                SubLeagueId: this.subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    this.setData(data.SettingDetail);
                }
            })
        }
    },
    watch:{
        subLeagueId(newVal){
            if(newVal > 0){
                this.leagueObj = this.leagueList.filter(n=>n.SubLeagueId == newVal)[0];
                this.roleList = [];
                this.files = [];
                GetLastLeagueEnrollDetail({
                    SubLeagueId: this.subLeagueId
                }).then(data=>{
                    if(data.Code == 0){
                        if(data.SettingDetail){
                            let {
                                BaseInfo: {
                                    FieldsOptions
                                },
                                RoleInfo,
                                GroupInfo
                            } = data.SettingDetail;
                            let {
                                jersey_number = 0,
                                portrait_photo = 0
                            } = FieldsOptions || {};
                            this.field = {
                                jerseyNumber: jersey_number,
                                portraitImg: portrait_photo
                            };
                            this.getTeamUserRoleList(RoleInfo);
                            this.getLeagueEventsGroup(GroupInfo);
                            this.addFile();
                        }else{
                            this.getTeamUserRoleList();
                            this.getLeagueEventsGroup();
                            this.addFile();
                        }
                    }
                })
            }
        }
    },
    computed:{
        GameTimeOptions(){
            var _this = this;
            return {
                disabledDate(time){
                    return time.getTime() > new Date(_this.leagueObj.GameBeginTime).getTime();
                }
            }
        },
        startGameTimeOptions(){
            var _this = this;
            return {
                disabledDate(time){
                    if(_this.form.time[1]){
                        return time.getTime() > new Date(_this.form.time[1]).getTime();
                    }else{
                        return false;
                    }
                }
            }
        },
        endGameTimeOptions(){
            var _this = this;
            return {
                disabledDate(time){
                    if(_this.form.time[0]){
                        return time.getTime() < new Date(_this.form.time[0]).getTime();
                    }else{
                        return false;
                    }
                }
            }
        }
    },
    methods:{
        setData(settingDetail){
            if(!settingDetail)return;
            let {
                LeagueBaseInfo,
                BaseInfo: {
                    EnrollBeginTime,
                    EnrollEndTime,
                    EnrollNotice,
                    FieldsOptions
                },
                RoleInfo,
                GroupInfo,
                InstrumentInfo
            } = settingDetail;
            let {
                jersey_number = 0,
                portrait_photo = 0
            } = FieldsOptions || {};
            this.leagueObj = LeagueBaseInfo;
            let form = {
                time: [EnrollBeginTime, EnrollEndTime],
                detail: EnrollNotice
            }
            this.form = form;
            this.field = {
                jerseyNumber: jersey_number,
                portraitImg: portrait_photo
            };
            let groups = [],
                files = [];
            //角色
            this.getTeamUserRoleList(RoleInfo);
            //分组
            for(var i=0;i<GroupInfo.length;i++){
                let {
                    LeagueGroupId,
                    EventsId,
                    LeagueGroupName,
                    LeagueGroupDesc,
                    GroupType,
                    EnrollWay: EWay,
                    GenderLimit,
                    MaleBirthdayMin: MBMin,
                    MaleBirthdayMax: MBMax,
                    FemaleBirthdayMin: FBMin,
                    FemaleBirthdayMax: FBMax,
                    AthletesMin,
                    AthletesMax,
                    MaleAthletesMin,
                    MaleAthletesMax,
                    FemaleAthletesMin,
                    FemaleAthletesMax
                } = GroupInfo[i];
                let EnrollWay = [];
                if([1,3].indexOf(EWay) > -1){
                    EnrollWay.push(1);
                }
                if([2,3].indexOf(EWay) > -1){
                    EnrollWay.push(2);
                }
                let MaleBirthdayMin = MBMin ? this.setDate(MBMin) : [null, 1, 1],
                    MaleBirthdayMax = MBMax ? this.setDate(MBMax) : [null, 12, 31],
                    FemaleBirthdayMin = FBMin ? this.setDate(FBMin) : [null, 1, 1],
                    FemaleBirthdayMax = FBMax ? this.setDate(FBMax) : [null, 12, 31];
                groups.push({
                    LeagueGroupId,
                    EventsId,
                    LeagueGroupName,
                    LeagueGroupDesc,
                    GroupType,
                    EnrollWay,
                    GenderLimit,
                    MaleBirthdayMin,
                    MaleBirthdayMax,
                    FemaleBirthdayMin,
                    FemaleBirthdayMax,
                    AthletesMin,
                    AthletesMax,
                    MaleAthletesMin,
                    MaleAthletesMax,
                    FemaleAthletesMin,
                    FemaleAthletesMax
                })
            }
            this.groups = groups;
            //法律文件
            InstrumentInfo = InstrumentInfo || [];
            for(var i=0;i<InstrumentInfo.length;i++){
                let obj = InstrumentInfo[i];
                let listId = nanoid();
                files.push({
                    listId,
                    name: obj.InstrumentName,
                    url: obj.InstrumentUrl,
                    state: 0
                })
            }
            this.files = files;
            this.addFile();
        },
        getTeamUserRoleList(RoleInfo){
            if(!this.leagueObj.EventsId)return;
            GetTeamUserRoleList({
                EventsId: this.leagueObj.EventsId
            }).then(data=>{
                if(data.Code == 0){
                    let TeamUserRoleList = data.TeamUserRoleList || [];
                    let arr = [];
                    for(var i=0;i<TeamUserRoleList.length;i++){
                        let obj = TeamUserRoleList[i];
                        arr.push({
                            isExist: true,
                            roleId: obj.RoleId,
                            name: obj.RoleName,
                            isAthlete: obj.IsAthlete,
                            isMerger: obj.IsAthlete != 1,
                            min:null,
                            max:null
                        })
                    }
                    if(RoleInfo && RoleInfo.length > 0){
                        for(var i=0;i<arr.length;i++){
                            let obj = arr[i];
                            let filterArr = RoleInfo.filter(n=>n.RoleId == obj.roleId);
                            if(filterArr.length > 0){
                                let {
                                    RoleId,
                                    RoleName,
                                    IsAthlete,
                                    CanPartTime,
                                    PeopleMin,
                                    PeopleMax
                                } = filterArr[0];
                                arr[i] = {
                                    isExist: true,
                                    roleId: RoleId,
                                    name: RoleId < 1000 ? RoleName : null,
                                    nameValue: RoleId >= 1000 ? RoleName : null,
                                    isAthlete: IsAthlete,
                                    isMerger: CanPartTime == 1,
                                    min: PeopleMin || null,
                                    max: PeopleMax || null
                                };
                            }else{
                                arr[i].isExist = false;
                            }
                        }
                        for(var i=0;i<RoleInfo.length;i++){
                            let {
                                RoleId,
                                RoleName,
                                IsAthlete,
                                CanPartTime,
                                PeopleMin,
                                PeopleMax
                            } = RoleInfo[i];
                            let filterArr = arr.filter(n=>n.roleId == RoleId);
                            if(filterArr.length == 0){
                                arr.push({
                                    isExist: true,
                                    roleId: RoleId,
                                    name: RoleId < 1000 ? RoleName : null,
                                    nameValue: RoleId >= 1000 ? RoleName : null,
                                    isAthlete: IsAthlete,
                                    isMerger: CanPartTime == 1,
                                    min: PeopleMin || null,
                                    max: PeopleMax || null
                                });
                            }
                        }
                    }
                    this.roleList = arr;
                    this.addRole();
                }
            })
        },
        getLeagueEventsGroup(oldGroupList){
            if(!this.leagueObj.SubLeagueId)return;
            GetLeagueEventsGroup({
                SubLeagueId: this.leagueObj.SubLeagueId
            }).then(data=>{
                if(data.Code == 0){
                   var GroupList = data.GroupList;
                   var arr = [];
                   for(var i=0;i<GroupList.length;i++){
                        let obj = GroupList[i];
                        let groupObj = {};
                        let filterArr = [];
                        if(oldGroupList && oldGroupList.length > 0){
                            for(var j=0;j<oldGroupList.length;j++){
                                if(oldGroupList[j].LeagueGroupName == obj.GroupName && oldGroupList[j].GroupType == obj.GroupType){
                                    filterArr = [oldGroupList[j]];
                                    oldGroupList.splice(j,1);
                                    break;
                                }
                            }
                        }
                        if(filterArr.length > 0){
                            let {
                                LeagueGroupDesc,
                                GenderLimit,
                                AthletesMin,
                                AthletesMax,
                                MaleAthletesMin,
                                MaleAthletesMax,
                                FemaleAthletesMin,
                                FemaleAthletesMax,
                                EnrollWay: EWay,
                                MaleBirthdayMin: MBMin,
                                MaleBirthdayMax: MBMax,
                                FemaleBirthdayMin: FBMin,
                                FemaleBirthdayMax: FBMax,
                            } = filterArr[0];
                            let EnrollWay = [];
                            if([1,3].indexOf(EWay) > -1){
                                EnrollWay.push(1);
                            }
                            if([2,3].indexOf(EWay) > -1){
                                EnrollWay.push(2);
                            }
                            let MaleBirthdayMin = MBMin ? this.setDate(MBMin) : [null, 1, 1],
                                MaleBirthdayMax = MBMax ? this.setDate(MBMax) : [null, 12, 31],
                                FemaleBirthdayMin = FBMin ? this.setDate(FBMin) : [null, 1, 1],
                                FemaleBirthdayMax = FBMax ? this.setDate(FBMax) : [null, 12, 31];
                            groupObj = {
                                LeagueGroupId: obj.GroupId,
                                EventsId: obj.EventsId,
                                LeagueGroupName: obj.GroupName,
                                LeagueGroupDesc,
                                GroupType: obj.GroupType,
                                EnrollWay,
                                GenderLimit,
                                MaleBirthdayMin,
                                MaleBirthdayMax,
                                FemaleBirthdayMin,
                                FemaleBirthdayMax,
                                AthletesMin,
                                AthletesMax,
                                MaleAthletesMin,
                                MaleAthletesMax,
                                FemaleAthletesMin,
                                FemaleAthletesMax
                            }
                        }else{
                            let EnrollWay = [],
                                GenderLimit = 3;
                            if(obj.GroupType == 2){
                                EnrollWay = [1];
                            }else{
                                EnrollWay = [1,2];
                            }
                            if(obj.GroupName.indexOf('男') > -1){
                                GenderLimit = 1;
                            }else if(obj.GroupName.indexOf('女') > -1){
                                GenderLimit = 2;
                            }else if(obj.GroupName.indexOf('混合') > -1){
                                GenderLimit = 4;
                            }
                            groupObj = {
                                LeagueGroupId: obj.GroupId,
                                EventsId: obj.EventsId,
                                LeagueGroupName: obj.GroupName,
                                LeagueGroupDesc: "",
                                GroupType: obj.GroupType,
                                EnrollWay,
                                GenderLimit,
                                MaleBirthdayMin:[null, 1, 1],
                                MaleBirthdayMax:[null, 12, 31],
                                FemaleBirthdayMin:[null, 1, 1],
                                FemaleBirthdayMax:[null, 12, 31],
                                AthletesMin: [1,3].includes(obj.GroupType) ? 1 : null,
                                AthletesMax: null,
                                MaleAthletesMin: null,
                                MaleAthletesMax: null,
                                FemaleAthletesMin: null,
                                FemaleAthletesMax: null
                            }
                        }
                        arr.push(groupObj)
                    }
                    this.groups = arr;
                }
            })
        },
        setDate(date){
            var date = new Date(date);
            return [date.getFullYear(), date.getMonth()+1, date.getDate()];
        },
        addRole(){
            var roleList = this.roleList;
            var obj = roleList[roleList.length - 1];
            if(roleList.length == 0 || obj.name || obj.nameValue){
                roleList.push({
                    isExist: true,
                    nameValue: null,
                    isAthlete: 0,
                    isMerger: true,
                    min:null,
                    max:null
                })
                this.$set(this,'roleList',roleList)
            }else{
                this.$message.error('请先填写前一个的身份名称');
            }
        },
        removeRole(index){
            var roleList = this.roleList;
            roleList.splice(index,1);
        },
        addFile(){
            var files = this.files;
            let obj = files[files.length - 1];
            if(files.length == 0 || (obj.url && obj.name)){
                let listId = nanoid();
                files.push({
                    listId,
                    name: "",
                    state: 0//1上传中 -1上传失败 2就是成功
                })
            }else{
                this.$message.error('请先上传前一个的文件和文件名称');
            }
        },
        removeFile(index){
            var files = this.files;
            files.splice(index,1);
        },
        fileChange(event,index){
            let files = event.target.files, file;
            let list = this.files;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr = ['pdf','application/pdf'];
                if(acceptArr.indexOf(file.type) == -1){
                    this.$message.error('不支持此文件类型');
                    return;
                }
                if (file.size > 100*1024*1024) {
                    this.$message.error('文件大小不能超过 100MB!');
                    return;
                }
                let obj = list[index];
                obj.size = formatFileSize(file.size);
                obj.state = 1;
                obj.url = "";
                if(!obj.name){
                    obj.name = file.name.replace(/.pdf/g,'').substr(0,20); 
                }
                this.$set(this.files,index,obj)
                this.ossUpload({
                    listId: obj.listId,
                    fileData: file
                })
            }
            event.target.value = "";
        },
        ossUpload({listId, fileData}){
            const _this = this;
            const options = this.UploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': fileData.name,
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            // console.log(client)
            client.put(nameID, fileData, headers).then(function (r1) {
                _this.fileUploadState({listId, state: 2,url: r1.url});
                console.log('put success:');
            }).catch(function (err) {
                _this.fileUploadState({listId, state: -1});
                console.log('error: '+JSON.stringify(err));
            });
        },
        fileUploadState({listId, state, url}){
            var list = this.files;
            var fileObj = null,
                fileIndex = -1;
            for(var i=0;i<list.length;i++){
                if(list[i].listId == listId){
                    fileObj = list[i];
                    fileIndex = i;
                    break;
                }
            }
            if(fileIndex > -1){
                if(state == 2){
                    fileObj.url = url;
                    fileObj.state = 2;
                }else {
                    fileObj.state = -1;
                }
                this.$set(this.files,fileIndex,fileObj);
            }
        },
        save(){
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    let fieldList = this.fieldList,
                        fieldInfo = [];
                    for(var i=0;i<fieldList.length;i++){
                        let obj = fieldList[i];
                        fieldInfo.push({
                            "LeagueEnrollFieldId": 0,
                            "LeagueEnrollId": this.Id,
                            "FieldName": obj.name,
                            "FieldType": obj.children ? 0 : 1,
                            "FieldDesc": "",
                            "DisplayIndex": i + 1,
                            "IsRequired": obj.isRequired
                        })
                    }
                    let roleList = this.roleList,
                        roleInfo = [];
                    for(var i=0;i<roleList.length;i++){
                        let obj = roleList[i];
                        if(obj.isExist && (obj.name || obj.nameValue)){
                            let min = obj.min,
                                max = obj.max;
                            min = min ? min < 0 ? 0 : min : 0;
                            max = max ? max < 0 ? 0 : max : 0;
                            roleInfo.push({
                                "Id": 0,
                                "LeagueEnrollId": this.Id,
                                "EventsId": this.leagueObj.EventsId,
                                "RoleId": obj.roleId || 0,
                                "RoleName": obj.name || obj.nameValue,
                                "IsAthlete": obj.isAthlete,
                                "CanPartTime": obj.isMerger ? 1 : 0,
                                "OrderBy": i + 1,
                                "PeopleMin": min,
                                "PeopleMax": max
                            })
                        }
                    }
                    let groups = this.groups,
                        groupInfo = [];
                    for(var i=0;i<groups.length;i++){
                        let obj = groups[i];
                        let MaleBirthdayMin,
                            MaleBirthdayMax,
                            FemaleBirthdayMin,
                            FemaleBirthdayMax,
                            MaleAthletesMin,
                            MaleAthletesMax,
                            FemaleAthletesMin,
                            FemaleAthletesMax;
                        if([1,3,4].includes(obj.GenderLimit)){
                            let BMin = obj.MaleBirthdayMin,
                                BMax = obj.MaleBirthdayMax,
                                AMin = obj.MaleAthletesMin,
                                AMax = obj.MaleAthletesMax;
                            AMin = AMin ? AMin < 0 ? null : AMin : null;
                            AMax = AMax ? AMax < 0 ? null : AMax : null;
                            MaleBirthdayMin = BMin[0] ? dateFormat(BMin.join("-"),'yyyy-MM-dd') : null;
                            MaleBirthdayMax = BMax[0] ? dateFormat(BMax.join("-"),'yyyy-MM-dd') : null;
                            MaleAthletesMin = AMin;
                            MaleAthletesMax = AMax;
                        }
                        if([2,3,4].includes(obj.GenderLimit)){
                            let BMin = obj.FemaleBirthdayMin,
                                BMax = obj.FemaleBirthdayMax,
                                AMin = obj.FemaleAthletesMin,
                                AMax = obj.FemaleAthletesMax;
                            AMin = AMin ? AMin < 0 ? null : AMin : null;
                            AMax = AMax ? AMax < 0 ? null : AMax : null;
                            FemaleBirthdayMin = BMin[0] ? dateFormat(BMin.join("-"),'yyyy-MM-dd') : null;
                            FemaleBirthdayMax = BMax[0] ? dateFormat(BMax.join("-"),'yyyy-MM-dd') : null;
                            FemaleAthletesMin = AMin;
                            FemaleAthletesMax = AMax;
                        }
                        let EnrollWay = 1;
                        if(obj.EnrollWay.length >= 2){
                            EnrollWay = 3;
                        }else if(obj.EnrollWay[0] == 1){
                            EnrollWay = 1;
                        }else if(obj.EnrollWay[0] == 2){
                            EnrollWay = 2;
                        }
                        groupInfo.push({
                            "Id": 0,
                            "LeagueEnrollId": this.Id,
                            "LeagueGroupId": obj.LeagueGroupId,
                            "EventsId": obj.EventsId,
                            "LeagueGroupName": obj.LeagueGroupName,
                            "LeagueGroupDesc": obj.LeagueGroupDesc,
                            "GroupType": obj.GroupType,
                            "EnrollWay": EnrollWay,
                            "GenderLimit": obj.GenderLimit,
                            MaleBirthdayMin,
                            MaleBirthdayMax,
                            FemaleBirthdayMin,
                            FemaleBirthdayMax,
                            "AthletesMin": obj.AthletesMin ? obj.AthletesMin < 0 ? null : obj.AthletesMin : null,
                            "AthletesMax": obj.AthletesMax ? obj.AthletesMax < 0 ? null : obj.AthletesMax : null,
                            MaleAthletesMin,
                            MaleAthletesMax,
                            FemaleAthletesMin,
                            FemaleAthletesMax
                        })
                    }
                    let files = this.files,
                        instrumentInfo = [];
                    for(var i=0;i<files.length;i++){
                        let obj = files[i];
                        if(obj.url && obj.name){
                            instrumentInfo.push({
                                "LeagueInstrumentId": 0,
                                "LeagueEnrollId": this.Id,
                                "InstrumentName": obj.name,
                                "InstrumentUrl": obj.url
                            })
                        }
                    }
                    let sendData = {
                        "SettingInfo": {
                            "LeagueBaseInfo": this.leagueObj,
                            "BaseInfo": {
                                "LeagueEnrollId": this.Id,
                                "LeagueId": this.leagueObj.SubLeagueId,
                                "LeagueName": this.leagueObj.SubLeagueName,
                                "EnrollBeginTime": this.form.time[0],
                                "EnrollEndTime": this.form.time[1] || '2099-12-31',
                                "EnrollNotice": this.form.detail,
                                "FieldsOptions": {
                                    "jersey_number": this.field.jerseyNumber,
                                    "portrait_photo": this.field.portraitImg
                                }
                            },
                            "FieldInfo": fieldInfo,
                            "RoleInfo": roleInfo,
                            "GroupInfo": groupInfo,
                            "InstrumentInfo": instrumentInfo
                        }
                    }
                    if(this.type == 0){
                        CreateLeagueEnroll(sendData).then(data=>{
                            if(data.Code == 0){
                                this.$message.success('报名创建成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        })
                    }else{
                        EditLeagueEnroll(sendData).then(data=>{
                            if(data.Code == 0){
                                this.$message.success('报名修改成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        })
                    }
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        }
    }
}
</script>
<style scoped>
.role {
    margin-left: 30px;
}
.role ul{
    margin-bottom: 20px;
}
.role li{
    display: flex;
    align-items: center;
    color: #606266;
    margin-bottom: 5px;
}
.role li .el-input{
    width: 50px;
}
.role li > div:nth-of-type(1){
    width: 150px;
}
.role li > div:nth-of-type(1) .el-input{
    width: 100px;
}
.role li > div:nth-of-type(3){
    margin-left: 20px;
}
.role li > div:nth-of-type(2) span:nth-of-type(2){
    margin: 0 10px;
}
.group_limit .el-form-item{
    margin-bottom: 10px;
}
.group_limit .more > div{
    margin-bottom: 5px;
}
.group_limit .more.number > div,
.group_limit .more > div:last-of-type{
    margin-bottom: 0;
}
.group_limit .more{
    color: #606266;
}
.group_limit .more.number{
    display: flex;
}
.group_limit .more.number>span{
    margin:0 10px;
}
.group_limit .more .label{
    display: inline-block;
    width: 80px;
    text-align: right;
}
.group_limit .more.number>div:last-of-type .label{
    width: auto;
}
.group_limit .more .el-input{
    width: 50px;
}
.files{
    margin-left: 10px;
}
.files ul{
    margin-bottom: 20px;
}
.files li{
    display: flex;
    align-items: center;
    color: #606266;
    margin-bottom: 10px;
}
.files li>span:nth-of-type(1){
    width: 30px;
    font-size: 22px;
}
.files li .button .el-button{
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.files li .button input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.files li .el-form-item{
    margin-bottom: 0;
    margin-right: 15px;
}
.files li .state{
    width: 140px;
    margin: 0 10px;
}
.files li .state .state1{
    color: #333333;
}
.files li .state .state2{
    color: #409eff;
}
.files li .state .state3{
    color: #f56c6c;
}
</style>
<style>

#EnrollDetail .role li .el-input input{
    height: 28px;
    line-height: 28px;
    padding: 0 5px;
}
#EnrollDetail .group_limit .more .el-input input{
    padding: 0 5px;
}
#EnrollDetail .group_limit .more + .el-form-item__error{
    margin-top: 2px;
}
#EnrollDetail .group_limit .el-form-item__error{
    margin-top: -6px;
}
</style>